import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

import { gsap } from "gsap"
// import { GSDevTools } from "gsap/dist/GSDevTools"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

import {
  StepWrapper,
  Step,
  Panel,
  PanelContent,
  Label,
} from "../../../styles/how-it-works"
import { useTranslateValue } from "../../../data/TranslateContext"

const PickCup = styled.div`
  position: absolute;
  left: 65%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 150px;

  user-select: none;
`

export default function Pick() {
  const images = useStaticQuery(graphql`
    query pickStepQuery {
      pickCup: file(relativePath: { eq: "how-it-works/pick-cup.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [triggerRef, inView] = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (inView) {
      clickFeature()
    }
  }, [inView]) // eslint-disable-line react-hooks/exhaustive-deps

  let cupRef = useRef(null)
  const [featureTimeline] = useState(gsap.timeline({ paused: true }))

  const actionLinesPaths = [
    "M0.228888136,1.8251608 C0.693251526,2.44276538 1.17288748,3.05292158 1.66763992,3.65562821 C2.06711663,4.1422701 2.78545769,4.21293144 3.27209958,3.81345473 C3.75874147,3.41397802 3.8294028,2.69563696 3.42992609,2.20899507 C2.95565392,1.63123747 2.49604885,1.04656303 2.05124395,0.454971285 C1.67287656,-0.0482583762 0.958200947,-0.14947925 0.454971285,0.228888136 C-0.0482583762,0.607255522 -0.14947925,1.32193114 0.228888136,1.8251608 Z",
    "M6.41509184,8.7740603 C6.96964978,9.30639057 7.53735454,9.83179486 8.11805176,10.350274 C8.58769777,10.7696003 9.30835226,10.7288082 9.72767862,10.2591622 C10.147005,9.78951616 10.1062128,9.06886167 9.63656682,8.64953531 C9.07623125,8.14923622 8.52866028,7.64246552 7.99399441,7.12923001 C7.53978707,6.6932281 6.81812988,6.70798653 6.38212798,7.16219387 C5.94612608,7.61640121 5.9608845,8.33805839 6.41509184,8.7740603 Z",
    "M13.5333388,14.7148441 C14.1519778,15.1661355 14.7816206,15.6111123 15.4221158,16.0497737 C15.9415713,16.4055377 16.6510769,16.2728394 17.0068408,15.7533839 C17.3626047,15.2339284 17.2299064,14.5244229 16.7104509,14.168659 C16.0885064,13.7427025 15.4773212,13.31077 14.877041,12.8728711 C14.3683942,12.501818 13.6552565,12.6133597 13.2842034,13.1220065 C12.9131503,13.6306533 13.024692,14.343791 13.5333388,14.7148441 Z",
    "M21.3327854,19.7457243 C21.9957546,20.1245257 22.6677811,20.497576 23.3487125,20.8648715 C23.9028437,21.1637706 24.5943613,20.9568642 24.8932604,20.4027331 C25.1921595,19.848602 24.9852531,19.1570843 24.431122,18.8581852 C23.7664503,18.4996603 23.1106582,18.1356219 22.4638964,17.7660809 C21.917233,17.4537332 21.2208669,17.6436836 20.9085192,18.190347 C20.5961715,18.7370105 20.786122,19.4333766 21.3327854,19.7457243 Z",
    "M29.5219948,23.9278679 C30.2160612,24.2444935 30.9175122,24.5558112 31.6261888,24.8618146 C32.2042099,25.1114016 32.8751187,24.8451531 33.1247056,24.2671321 C33.3742925,23.689111 33.1080441,23.0182022 32.530023,22.7686152 C31.835496,22.4687215 31.1481989,22.1636856 30.4682905,21.8535187 C29.8954747,21.5922064 29.2192804,21.8447298 28.957968,22.4175455 C28.6966557,22.9903612 28.9491791,23.6665556 29.5219948,23.9278679 Z",
    "M38.0587205,27.4222546 C38.7749159,27.6846326 39.4970085,27.9420359 40.2248265,28.1944558 C40.8196719,28.4007585 41.4691309,28.0857824 41.6754337,27.4909369 C41.8817364,26.8960915 41.5667603,26.2466325 40.9719148,26.0403297 C40.2564861,25.7922066 39.5467981,25.5392252 38.8430231,25.2813974 C38.2518417,25.0648182 37.5970224,25.3684933 37.3804432,25.9596747 C37.163864,26.550856 37.4675391,27.2056754 38.0587205,27.4222546 Z",
    "M46.8083172,30.2971891 C47.5406112,30.5119715 48.2774359,30.722004 49.0185982,30.9272763 C49.6253613,31.0953254 50.2534707,30.7396775 50.4215199,30.1329144 C50.589569,29.5261513 50.233921,28.8980419 49.6271579,28.7299928 C48.8970223,28.5277744 48.1712424,28.3208902 47.4500115,28.1093526 C46.8458572,27.9321536 46.2124452,28.2782694 46.0352462,28.8824237 C45.8580472,29.4865781 46.2041629,30.1199901 46.8083172,30.2971891 Z",
    "M55.7219018,32.6276231 C56.465561,32.7995757 57.2124081,32.9669099 57.9622183,33.1296152 C58.5775035,33.2631291 59.1845253,32.8725763 59.3180392,32.257291 C59.4515532,31.6420057 59.0610004,31.034984 58.4457151,30.90147 C57.7059364,30.7409416 56.9691372,30.5758586 56.2355425,30.4062332 C55.6221226,30.2643952 55.0098651,30.6466879 54.8680272,31.2601078 C54.7261892,31.8735277 55.1084819,32.4857852 55.7219018,32.6276231 Z",
    "M64.7042791,34.4545043 C65.4564135,34.5873013 66.2103282,34.7155114 66.9657515,34.8391242 C67.5870926,34.9407966 68.1732108,34.5195219 68.2748832,33.8981808 C68.3765556,33.2768398 67.9552809,32.6907216 67.3339399,32.5890492 C66.5879164,32.4669745 65.8434143,32.3403652 65.100705,32.2092322 C64.4806902,32.0997622 63.889326,32.5136405 63.779856,33.1336553 C63.670386,33.75367 64.0842643,34.3450343 64.7042791,34.4545043 Z",
    "M73.7680346,35.8234228 C74.5275879,35.919152 75.2872995,36.0102061 76.0468275,36.096578 C76.6724002,36.1677169 77.2371965,35.7182594 77.3083354,35.0926867 C77.3794742,34.4671139 76.9300167,33.9023176 76.304444,33.8311788 C75.5540709,33.7458479 74.8035209,33.6558919 74.0531356,33.5613182 C73.4284727,33.4825897 72.8582613,33.9251571 72.7795328,34.54982 C72.7008043,35.174483 73.1433717,35.7446944 73.7680346,35.8234228 Z",
    "M82.910601,36.7505135 C83.6753488,36.8092566 84.4381789,36.8631147 85.198645,36.9120887 C85.8269481,36.9525514 86.3690899,36.4760127 86.4095526,35.8477096 C86.4500153,35.2194066 85.9734766,34.6772648 85.3451735,34.636802 C84.5940746,34.5884312 83.8406089,34.5352344 83.0852221,34.4772102 C82.4574668,34.42899 81.9094803,34.898796 81.86126,35.5265513 C81.8130397,36.1543066 82.2828457,36.7022932 82.910601,36.7505135 Z",
    "M92.0644733,37.2235184 C92.834586,37.2434755 93.5998226,37.2581371 94.3595724,37.2675239 C94.9891289,37.2753022 95.5057909,36.7712512 95.5135691,36.1416947 C95.5213474,35.5121381 95.0172965,34.9954762 94.3877399,34.9876979 C93.6383101,34.9784386 92.8833729,34.9639743 92.1235385,34.9442836 C91.4941452,34.9279732 90.9706989,35.4249751 90.9543885,36.0543684 C90.9380781,36.6837617 91.43508,37.207208 92.0644733,37.2235184 Z",
    "M101.262965,37.2028333 C102.038703,37.1778062 102.8047,37.1467422 103.560073,37.1097086 C104.188922,37.0788781 104.673712,36.5441019 104.642882,35.9152526 C104.612051,35.2864033 104.077275,34.8016133 103.448426,34.8324438 C102.705825,34.8688512 101.952536,34.8993999 101.189446,34.9240189 C100.560168,34.9443209 100.066496,35.4709088 100.086798,36.100186 C100.1071,36.7294632 100.633688,37.2231352 101.262965,37.2028333 Z",
    "M110.46673,36.571752 C111.250789,36.4854394 112.015249,36.3915773 112.758714,36.2903572 C113.382563,36.2054222 113.819439,35.6308389 113.734504,35.0069896 C113.649569,34.3831402 113.074986,33.9462638 112.451137,34.0311988 C111.727243,34.1297546 110.982137,34.2212402 110.217245,34.3054429 C109.591421,34.3743363 109.139939,34.937516 109.208833,35.56334 C109.277726,36.189164 109.840906,36.6406455 110.46673,36.571752 Z",
  ]
  let actionLinesRefs = useRef([])

  const setupFeatureTimeline = () => {
    featureTimeline.fromTo(
      cupRef.current,
      {
        x: "-=100",
        y: "+=150",
        rotate: 18,
        scale: 0.4,
        transformOrigin: "75% 0%",
      },
      {
        x: "+=100",
        y: "-=150",
        duration: 1.5,
        rotate: 0,
        scale: 1,
        ease: "power4.out",
        force3D: false,
      },
      "-=1"
    )

    featureTimeline.fromTo(
      actionLinesRefs.current,
      {
        scale: 0,
      },
      {
        duration: 0.1,
        stagger: 0.05,
        scale: 1,
      },
      "-=1.5"
    )

    featureTimeline.play()
  }

  const clickFeature = e => {
    featureTimeline.restart()
    featureTimeline.play()
  }

  useEffect(() => {
    // gsap.registerPlugin(GSDevTools, MotionPathPlugin)
    gsap.registerPlugin(MotionPathPlugin)

    setupFeatureTimeline()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [{ language }] = useTranslateValue()

  return (
    <Step>
      <Panel ref={triggerRef}>
        <PanelContent>
          {language === "en" && <Label>Pick a Cup</Label>}
          {language === "fr" && <Label>Choisissez une Tasse</Label>}

          <StepWrapper>
            <PickCup
              onClick={clickFeature}
              ref={el => {
                cupRef.current = el
              }}
            >
              <Img fluid={images.pickCup.childImageSharp.fluid} />
            </PickCup>

            <svg
              width="276px"
              height="243px"
              viewBox="0 0 276 243"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              onClick={clickFeature}
            >
              <g
                transform="translate(0.000000, 20.000000)"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              >
                <polygon
                  stroke="#004E7D"
                  strokeWidth="1.14"
                  points="17.195 166.459 128.028 166.459 128.028 15.651 17.195 15.651"
                ></polygon>
                <polyline
                  stroke="#004E7D"
                  strokeWidth="0.384"
                  points="8.7157 63.2344 8.7157 8.1104 136.3727 8.1104 136.3727 173.9994 8.7157 173.9994 8.7157 123.8474"
                ></polyline>
                <polygon
                  stroke="#004E7D"
                  strokeWidth="1.14"
                  points="0.57 181.54 144.653 181.54 144.653 0.57 0.57 0.57"
                ></polygon>
                <path
                  d="M50.2865,34.3697 C50.2865,34.3697 45.9645,55.3047 45.6815,56.8487 C44.4355,59.3647 41.6725,59.9877 38.6635,59.9147 C35.5995,59.8397 32.1545,58.1727 31.6705,56.2727 L28.0795,34.0127"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M50.353,33.7081 C50.292,37.3661 45.247,40.2501 39.083,40.1471 C32.92,40.0461 27.972,36.9981 28.033,33.3391 C28.093,29.6811 33.139,26.7981 39.302,26.9001 C45.466,27.0011 50.414,30.0501 50.353,33.7081 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M83.9692,34.3697 C83.9692,34.3697 79.6472,55.3047 79.3642,56.8487 C78.1182,59.3647 75.3552,59.9877 72.3462,59.9147 C69.2832,59.8397 65.8382,58.1727 65.3532,56.2727 L61.7632,34.0127"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M84.0356,33.7081 C83.9746,37.3661 78.9296,40.2501 72.7656,40.1471 C66.6026,40.0461 61.6546,36.9981 61.7156,33.3391 C61.7756,29.6811 66.8216,26.7981 72.9846,26.9001 C79.1486,27.0011 84.0966,30.0501 84.0356,33.7081 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M117.9179,34.3697 C117.9179,34.3697 113.5969,55.3047 113.3129,56.8487 C112.0669,59.3647 109.3039,59.9877 106.2949,59.9147 C103.2319,59.8397 99.7869,58.1727 99.3019,56.2727 L95.7119,34.0127"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M117.9843,33.7081 C117.9243,37.3661 112.8783,40.2501 106.7143,40.1471 C100.5513,40.0461 95.6043,36.9981 95.6643,33.3391 C95.7243,29.6811 100.7703,26.7981 106.9343,26.9001 C113.0973,27.0011 118.0453,30.0501 117.9843,33.7081 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M50.2865,84.9507 C50.2865,84.9507 45.9645,105.8857 45.6815,107.4297 C44.4355,109.9467 41.6725,110.5697 38.6635,110.4957 C35.5995,110.4207 32.1545,108.7537 31.6705,106.8537 L28.0795,84.5937"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M50.353,84.2896 C50.292,87.9476 45.247,90.8306 39.083,90.7286 C32.92,90.6266 27.972,87.5796 28.033,83.9206 C28.093,80.2626 33.139,77.3796 39.302,77.4806 C45.466,77.5826 50.414,80.6306 50.353,84.2896 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M83.9692,84.9507 C83.9692,84.9507 79.6472,105.8857 79.3642,107.4297 C78.1182,109.9467 75.3552,110.5697 72.3462,110.4957 C69.2832,110.4207 65.8382,108.7537 65.3532,106.8537 L61.7632,84.5937"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M84.0356,84.2896 C83.9746,87.9476 78.9296,90.8306 72.7656,90.7286 C66.6026,90.6266 61.6546,87.5796 61.7156,83.9206 C61.7756,80.2626 66.8216,77.3796 72.9846,77.4806 C79.1486,77.5826 84.0966,80.6306 84.0356,84.2896 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M117.9179,84.9507 C117.9179,84.9507 113.5969,105.8857 113.3129,107.4297 C112.0669,109.9467 109.3039,110.5697 106.2949,110.4957 C103.2319,110.4207 99.7869,108.7537 99.3019,106.8537 L95.7119,84.5937"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M117.9843,84.2896 C117.9243,87.9476 112.8783,90.8306 106.7143,90.7286 C100.5513,90.6266 95.6043,87.5796 95.6643,83.9206 C95.7243,80.2626 100.7703,77.3796 106.9343,77.4806 C113.0973,77.5826 118.0453,80.6306 117.9843,84.2896 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M50.2865,134.7979 C50.2865,134.7979 45.9645,155.7329 45.6815,157.2759 C44.4355,159.7929 41.6725,160.4159 38.6635,160.3429 C35.5995,160.2679 32.1545,158.6009 31.6705,156.7009 L28.0795,134.4409"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M50.353,134.1363 C50.292,137.7943 45.247,140.6783 39.083,140.5753 C32.92,140.4743 27.972,137.4263 28.033,133.7673 C28.093,130.1093 33.139,127.2263 39.302,127.3283 C45.466,127.4293 50.414,130.4783 50.353,134.1363 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M83.9692,134.7979 C83.9692,134.7979 79.6472,155.7329 79.3642,157.2759 C78.1182,159.7929 75.3552,160.4159 72.3462,160.3429 C69.2832,160.2679 65.8382,158.6009 65.3532,156.7009 L61.7632,134.4409"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M84.0356,134.1363 C83.9746,137.7943 78.9296,140.6783 72.7656,140.5753 C66.6026,140.4743 61.6546,137.4263 61.7156,133.7673 C61.7756,130.1093 66.8216,127.2263 72.9846,127.3283 C79.1486,127.4293 84.0966,130.4783 84.0356,134.1363 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M117.9179,134.7979 C117.9179,134.7979 113.5969,155.7329 113.3129,157.2759 C112.0669,159.7929 109.3039,160.4159 106.2949,160.3429 C103.2319,160.2679 99.7869,158.6009 99.3019,156.7009 L95.7119,134.4409"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <path
                  d="M117.9843,134.1363 C117.9243,137.7943 112.8783,140.6783 106.7143,140.5753 C100.5513,140.4743 95.6043,137.4263 95.6643,133.7673 C95.7243,130.1093 100.7703,127.2263 106.9343,127.3283 C113.0973,127.4293 118.0453,130.4783 117.9843,134.1363 Z"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></path>
                <line
                  x1="17.3197"
                  y1="68.272"
                  x2="127.3757"
                  y2="68.272"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></line>
                <line
                  x1="17.3197"
                  y1="117.8731"
                  x2="126.9447"
                  y2="117.8731"
                  stroke="#004E7D"
                  strokeWidth="1.14"
                ></line>
                <line
                  x1="8.7157"
                  y1="71.1837"
                  x2="8.7157"
                  y2="115.9827"
                  stroke="#DE2731"
                  strokeWidth="3.102"
                ></line>
              </g>
              <g
                transform="translate(52.859934, 179.266817)"
                fill="#004E7D"
                fillRule="nonzero"
              >
                {actionLinesPaths.map((path, pathIndex) => (
                  <path
                    className="action-line-part"
                    key={`pick-line-${pathIndex}`}
                    d={path}
                    ref={el => {
                      actionLinesRefs.current[pathIndex] = el
                    }}
                  ></path>
                ))}
              </g>
            </svg>
          </StepWrapper>
        </PanelContent>
      </Panel>
    </Step>
  )
}
