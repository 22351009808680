import React from "react"
import styled from "styled-components"

import { useTranslateValue } from "../../../data/TranslateContext"
import Row from "../../../styles/Row"

import Pick from "./Pick"
import Peel from "./Peel"
import Place from "./Place"

const Wrapper = styled.section`
  transition: background 0.2s ease-in-out;
  background-color: ${props => props.theme.color.mediumBg};
`

const FlexRow = styled(Row)`
  position: relative;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2rem;
`

const TitleWrapper = styled(Row)`
  text-align: center;

  h1 {
    margin: 0;
  }

  p {
    font-style: italic;
    color: ${props => props.theme.color.primary};
  }
`

export default function HowItWorks(props) {
  const [{ language }] = useTranslateValue()

  return (
    <Wrapper>
      <FlexRow>
        <TitleWrapper>
          {language === "en" && <h1>How to Blend a f'real</h1>}
          {language === "fr" && <h1>Comment mélanger une f'real</h1>}

          {language === "en" && (
            <p>
              With just three easy steps, you'll be sipping a f'real in no time!
            </p>
          )}
          {language === "fr" && (
            <p>
              En seulement trois étapes faciles, vous siroterez un f'real en un
              rien de temps!
            </p>
          )}
        </TitleWrapper>
      </FlexRow>
      <FlexRow>
        <Pick />
        <Peel />
        <Place />
      </FlexRow>
    </Wrapper>
  )
}
